import React from 'react'
import { graphql } from 'gatsby'
import Img, { FixedObject } from 'gatsby-image'
import { IndexQueryQuery, PostByPathQuery } from '../../types/graphql-types'
import Meta from '../components/meta/meta'
import Layout from '../components/layout/layout'
import Container from 'components/pages/container'
import ContainerFluid from 'components/pages/container-fluid'
import { Link } from 'gatsby'
import Video from "components/video"
import BackgroundImage from 'gatsby-background-image'
import OGImage from 'images/the-dentists-at-gateway-crossing.jpg'
import Logo from 'images/logo.png'
import { Helmet } from 'react-helmet'

interface Props {
  data: IndexQueryQuery
  location: Location
}

const BlogQuestionsAboutDentalImplantsPage: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta
  const hero = data.hero?.childImageSharp?.fluid
  const hero_background = data.hero_background?.childImageSharp?.fluid
  const dental_implant_img = data.dental_implant_img?.childImageSharp?.fluid

  return (
    <Layout location={location}>
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <title>8 Questions to Ask Your Dentist About Dental Implants</title>
      <meta name="description" content="Have you had your dentist recommend dental implants to repair a lost tooth? Here are 8 questions to ask your dentist about dental implants." />
      <meta name="robots" content="follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="McCordsville Dentist Office The Dentists At Gateway Crossing" />
      <meta property="og:description" content="Have you had your dentist recommend dental implants to repair a lost tooth? Here are 8 questions to ask your dentist about dental implants." />
      <meta property="og:url" content="https://thedentistsatgc.com/" />
      <meta property="og:site_name" content="The Dentists At Gateway Crossing" />
      <meta property="og:updated_time" content="2021-02-05T05:10:25+00:00" />
      <meta property="og:image" content={OGImage} />
      <meta property="og:image:secure_url" content={OGImage} />
      <meta property="og:image:width" content="1080" />
      <meta property="og:image:height" content="1080" />
      <meta property="og:image:alt" content="McCordsville Dentist" />
      <meta property="og:image:type" content="image/jpeg" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="McCordsville Dentist Office The Dentists At Gateway Crossing" />
      <meta name="twitter:description" content="Have you had your dentist recommend dental implants to repair a lost tooth? Here are 8 questions to ask your dentist about dental implants." />
      <meta name="twitter:image" content={OGImage} />
      <meta name="google-site-verification" content="khYQGQ-T8YWkXa0QyKr6e2kNlFB3l0ZiFRA89VzVbaM" />
      <meta name="google-site-verification" content="Oll6PjpMRzNx1KbgKilxS6xPddvSbF2lDOa2rd7VkxA" />
      <meta name="msapplication-TileImage" content={Logo} />
      <script async src='https://tag.simpli.fi/sifitag/195cd770-bf42-013a-5143-0cc47a8ffaac'></script>
    </Helmet>
        <section id="blog-post-section">
          <Container>
            <div className="col-lg-12">
              <h1>
                8 Questions to Ask Your Dentist About Dental Implants
              </h1>
              <ul className="list-inline">
                <li className="list-inline-item article-time">
                  2022/03/07
                </li>
                <li className="list-inline-item badge badge-primary">
                  Dental Implants
                </li>
                <li className="list-inline-item badge badge-secondary">
                  8 Questions to Ask Your Dentist About Dental Implants
                </li>
              </ul>
                <Img fluid={dental_implant_img} className="blog-post-img"/>
              <h2 className="mb-3">
                8 Questions to Ask Your Dentist About Dental Implants
              </h2>
              <p>
                Have you had your dentist recommend dental implants to repair a lost tooth? If this is the case, you may have many important concerns. Concerns that need to be addressed. So, if you are thinking about dental implants, we have put together some helpful questions for you. Ask these question to your dentist about dental implants:
              </p>
              <ol>
                <li>
                  What Are Dental Implants
                </li>
                <p>Dental implants are used as a replacement for lost or severely damaged teeth, or in many cases, as a single tooth. A dental implant is simply a titanium screw that is placed into the jaw, where the missing tooth once was. After the bone has had time to heal, the screw will be firmly in place. </p>
                <p>A dental crown will then be fitted on top of the screw. This gives you a solid and durable replacement for a missing tooth.</p>
                <p>In the case of an entire arch, there will often be 4 implants placed evenly around the jawbone and an entire set of implant supported dentures will sit atop the implants. This is known as "all-on-4" dental implants.</p>
                <p>Dental implants can be used to replace partial arches or small groups of teeth as well in a similar fashion.</p>
                <li>
                  Are Dental Implants Safe
                </li>
                <p>Dental implant surgery is a low-risk surgical treatment that may be performed on nearly anybody in good health. It's a more long-lasting option than bridges and dentures. It may also assist you in achieving the smile makeover you desire.</p>
                <p>The clinic you choose will assess whether you are a good fit for the procedure. Some dentists will not perform the surgery if you use tobacco or have certain other risk factors or illnesses. Be sure to discuss all of your health issues with your dentist. </p>
                <li>
                  What Are Some Advantages to Dental Implants
                </li>
                <p><a href="https://thedentalblogs.com/the-advantages-of-dental-implants" target="_blank" rel="noreferrer">Dental implants have a lot of advantages.</a> Dental implants appear identical to natural teeth, so no one will be able to tell you have dental implants. You can chew and converse freely, knowing that your teeth will not fall out like dentures.</p>
                <p>Additionally, dental implants help you to retain the bone in your jaw. When you are missing teeth, your jawbone atrophies and shrinks. This can create multiple problems including changing your facial appearance. Thankfully, dental implants most often prevent jawbone shrinkage that is often created by tooth loss. </p>
                <li>
                  How Long Will The Implanted Tooth Last?
                </li>
                <p>In most cases, with good care, dental implants can last you the rest of your life. Dental implants do not require extra special care. Simply brush and floss like you would with your natural teeth - and see your dentist regularly so he can check for any possible problems that may be developing.</p>
                <p>Most people who maintain excellent oral hygiene find that their implants endure for a long time as a result of their hard work and dedication.</p>
                <li>
                  Does it hurt to get Dental Implants
                </li>
                <p>There's discomfort associated with the implant's installation. Local anesthetic is applied during the procedure and over the counter meds are most often sufficient for pain management afterwards. Soreness generally lasts two to five days following surgery.</p>
                <p>For dental implants, a bone graft is sometimes needed. </p>
                <li>
                  How Long Will the Surgery Take?
                </li>
                <p>The length of the procedure itself can depend on the patient and the exact type of implants, but it is often no more than a couple of hours to complete. Your dentist will be able to give you a more specific answer.</p>
                <p>After it is placed, you will need to allow some time for the jaw bone to bond with the implant and heal. This can take weeks or even months. Your dentist will inform you when they want you to come back in for the next appointment. </p>
                <p>Once the bone has had time to heal, the crown can be fitted to your new implant giving you the look and feel of a natural tooth once again. </p>
                <li>
                  Can My Body Reject the Implant
                </li>
                <p>Yes, that is always a possibility, but the chances are low. Sometimes a person may develop an allergy to the metal used. Also, implants may fail if the person with it fails to practice proper hygiene. Taking good care of your teeth and mouth will help ensure that the implant will be a success.</p>
                <li>
                  What is the Cost of Dental Implants?
                </li>
                <p>The prices can vary depending on your individual case. Your dentist should be able to give you a quote. There are many variables that can come into play, including:</p>
                <ul>
                  <li>Which office/dentist you see</li>
                  <li>How many implants are being placed</li>
                  <li>The possible need for extractions or bone grafts</li>
                </ul>
                <p className="mt-3">Sedation</p>
                <p>The dental crowns or fixtures being placed</p>
                <p>Because there are so many variables, the cost is extremely difficult to estimate. I would recommend taking caution when looking at estimates online, as they cannot take into account all of the specifics of your case. Every person is different and so are the dentists and their fees. It is best to see a dentist and get him to give you a specific quote. </p>
                <p>Conclusively, Don't be Afraid to ask Questions</p>
                <p>If you have additional concerns or questions, you should bring those up with your dentist. If he is unable to answer your questions to your satisfaction, you might consider visiting a different office. We help our patients get the answers they need here, and that is why we recommend you see us for help with dental implants. We will be happy to go over your treatment plan and answer all of the questions you have. </p>
              </ol>
            </div>
          </Container>
        </section>
    </Layout>
  )
}

export default BlogQuestionsAboutDentalImplantsPage

export const query = graphql`
  query BlogQuestionsAboutDentalImplantsPageQuery {
    hero: file(name: { eq: "hero-image" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    dental_implant_img: file(name: { eq: "man-smiling-about-dental-implants" }) {
      childImageSharp {
        fluid(maxWidth: 1130, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
        twitter
      }
    }
  }
`
